.video-captured{

  width: 90%;
  height: auto;
}
.video-preview{
  width: 90%;
  height: auto;

}
.play-video-container-div{
  width: fit-content;
  height: fit-content;
  margin: auto;
}
.close-icon-x{
  position: absolute;
  font-size: 1.3rem;
  color: rgba(255, 255, 255, 0.7);
  padding: .1em .4em;
  border-radius: 20px;
  cursor: pointer;
  z-index: 200;
  &:hover{
    color: rgb(255, 255, 255);

  }

}
.record-video-button{
  //margin: auto;
  position: absolute;
  bottom: 20px;
  right: 45%;


}