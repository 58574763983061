.video-container-div{


  width: fit-content;
}
.video-insert {
  //position: absolute;
  //right: 0;
  //bottom: 0;
  //display: flex;
  //margin: auto;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  //background: linear-gradient(89deg, #1211e0, #13f4b6, #11bee0, #f66f08, #f6082a, #f6ed08, #5d0ebe, #000000);
  //background-size: 1600% 1600%;
  //-webkit-animation: AnimationName 54s ease infinite;
  //-moz-animation: AnimationName 54s ease infinite;
  //-o-animation: AnimationName 54s ease infinite;
  //animation: AnimationName 54s ease infinite;
  //z-index: -100;
  //background-size: cover;
  //overflow: hidden;
}

.submission-component-div{
  width: 100%;
  //text-align: center;
  margin: .3em 0 .3em 0;
  padding: 0;
}
.submission-thumbnail-div{
  width: 5em;
  height: 5em;
  border: solid;
  margin:  1em auto;
}
.submission-component-card{
  text-align: center;
  background-color: rgb(87, 232, 65);
  width: 20em;
 margin: auto;

}
.submission-component-challenge-title{
}

.submission-component-card {
  background: linear-gradient(89deg, #1211e0, #13f4b6, #11bee0, #f66f08, #f6082a, #f6ed08, #5d0ebe, #000000);
  background-size: 1600% 1600%;
  -webkit-animation: AnimationName 54s ease infinite;
  -moz-animation: AnimationName 54s ease infinite;
  -o-animation: AnimationName 54s ease infinite;
  animation: AnimationName 54s ease infinite;
}
@-webkit-keyframes AnimationName {
  0%{background-position:0% 55%}
  50%{background-position:100% 46%}
  100%{background-position:0% 55%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 55%}
  50%{background-position:100% 46%}
  100%{background-position:0% 55%}
}
@-o-keyframes AnimationName {
  0%{background-position:0% 55%}
  50%{background-position:100% 46%}
  100%{background-position:0% 55%}
}
@keyframes AnimationName {
  0%{background-position:0% 55%}
  50%{background-position:100% 46%}
  100%{background-position:0% 55%}
}