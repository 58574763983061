.use-video-play-video-container-div{
  width: fit-content;
  height: fit-content;
  margin: auto;

}

.use-video-captured{

  width: 300px;
  height: auto;
}
.use-video-preview {
  width: 300px;
  height: auto;
}