.challenge-component-card{
  //margin: auto auto 1.2em;
  cursor: pointer;
  text-align: center;
  //background-color: rgb(87, 232, 65);
  width: 20em;
  margin: .5em auto;
}
.challenge-thumbnail-div{
  width: 2em;
  height:  fit-content;
 // border: solid;
  margin: auto;
}
.add-submission-picture-div{
  //width: 20em;
  //border: solid;
  //height: 5em;
  //background-color: #2fdf75;
}
.challenge-title-div{
  width: 12em;
}
.trash-icon-container-div{
 // background-color: #dfd02f;
  width: 2em;
  height: fit-content;
  margin: auto;
}